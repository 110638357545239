
import React from 'react';

function SiteMapPage(props) {

    const routes = [
        {
            url: '',
            name: 'Головна'
        },
        {
            url: 'information',
            name: 'Інформація'
        },
        {
            url: 'site-map',
            name: 'Мапа сайту'
        }
    ]

    return (<div className={'container col-md-8 m-auto min-vh-100 p-5'}>

        <h1>
            Мапа сайту
        </h1>

        <div className="d-flex flex-wrap mt-5">

            <div className={'lead text-secondary text-start  col-md-6 col-12'}>

                <h3>
                    <b>
                        Сторінки
                    </b>
                </h3>
                <br/>

                {
                    routes.map(link => <p className={'lead'}>
                        <a href={'/' + link.url} target={'_blank'} className={'nav-link'}> {link.name}</a>
                    </p>)
                }

            </div>

        </div>


    </div>);
}

export default SiteMapPage;
