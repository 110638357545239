
import React from 'react';

import HeaderComponents from "./header";
import FooterComponents from "./footer";

import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from "react-scroll-to-top";

function LayoutsComponents(props) {

    return (<div id={'layouts'} className={'page container-fluid'}>
        <HeaderComponents />

        <main className={'h-auto vh-100'}>
            {props.children}
        </main>

        {/*<FooterComponents />*/}

        <ScrollToTop top />
    </div>);
}

export default LayoutsComponents;
