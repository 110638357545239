
import React from 'react';

function PageNotFound(props) {

    return (<div className={'container bg-white p-md-5 p-5 col-md-6 col-12 m-auto vh-100 d-flex align-items-center '}>

        <div className={'lead text-secondary text-start'}>
            <h1>
               Помилка 404
            </h1>
            <br/>
            <h3>
                <b>
                    Сторінка не знайдена
                </b>
            </h3>
            <p className={'lead'}>
                Вибачте, ми не змогли знайти сторінку/посилання за яким ви звернулися. Спробуйте пізніше або поверність на Головну сторінку.
                <br/>
                <b>Вдалого вам дня!</b>
            </p>
            <br/>
            <a href="/" id={'btn-go-home'} className={'btn1 bg-dark rounded-5 p-3 align-items-center text-white text-decoration-none p-2'}>На Головну</a>
        </div>

    </div>);
}

export default PageNotFound;
