
import React, {useEffect, useState} from 'react';
import {JsonEditor} from "json-edit-react";
import {useParams} from "react-router";
import LocalJsonData from "../../../data";

function EditDataDetailPage(props) {

    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [load, setLoad] = useState(true);

    const [isEdit, setEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const {json} = useParams()
    const  {REACT_APP_PROD_API_URL} = process.env

    function init(){
        const _data = LocalJsonData(json);
        setData2(_data)
        fetch(`${REACT_APP_PROD_API_URL}/` + json)
            .then(response => response.json())
            .then(dataParty => setData(dataParty))
        setLoad(false)
    }

    useEffect(() => {
        init();
    }, [])

    // EDIT

    const handleEdit = (record) => {
        setEdit(true)
        setDataEdit(record)
    };

    const handleEditDataChange = (e) => setDataEdit({...dataEdit, text: e.target.value});

    const handleSave = async(e) => {

        e.preventDefault();

        await fetch(   `${REACT_APP_PROD_API_URL}/` + json + '/' + dataEdit.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: dataEdit.text }),
        }).then(res => {
            const newState = data.map(obj => {

                if (obj.id === dataEdit.id) {
                    return {...obj,
                        text: dataEdit.text
                    };
                }
                return obj;
            });

            setData(newState)
            handleCancelEdit()
        }).catch(e => console.log(e))
    }

    const handleCancelEdit = () => {
        setEdit(false)
        setDataEdit()
    }

    return (<div className={'container col-md-6 col-12 m-auto min-vh-100 p-md-5 p-0'}>
        <a href="/edit-data" className={'nav-link mb-3 mt-2 d-flex align-items-center'}>
            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M5 12h14M5 12l4-4m-4 4 4 4"/>
            </svg>
            Назад
        </a>

        <h1 className={'text-secondary opacity-25'}>
            Редагування даних <b className={'opacity-100'}> - {data2?.title}</b>
        </h1>

        {load && 'Loading...'}

        {data && <div className={''}>

            <div className="border rounded-5 shadow-sm p-5 mt-5">

                <div className={'lead text-secondary'}>

                    <div className="edit-texts">
                        <h3>
                            <b>
                                {isEdit ? ('Редагування заголовку #' + dataEdit.id): data2?.label}
                            </b>
                        </h3>

                        {
                            isEdit && <div className={'mt-5 text-center'}>
                                <input type="text" value={dataEdit.text} onChange={handleEditDataChange} className={'form-control rounded-pill p-3'}/>
                                <br/>
                                <button type={'submit'} onClick={handleSave} className={'btn btn-dark rounded-pill p-3 col-md-12 col-12'}>Зберегти</button>
                                <button type={'button'} onClick={handleCancelEdit} className={'btn m-1 mt-3 rounded-pill p-2'}>Скасувати</button>
                            </div>
                        }

                        <div className={isEdit && 'd-none'}>
                            {
                                data && data.map(text => <div key={text.id} className={'d-flex justify-content-between mt-2 border-bottom pb-2'}>
                                    <div className={'d-flex align-items-center'}>
                                        <small className="text-secondary p-1">#{text.id}</small>
                                        <div className={'text-dark'} dangerouslySetInnerHTML={{ __html: text.text }} />
                                    </div>
                                    <button type={'button'} onClick={() => handleEdit(text)} className={'btn btn-secondary m-1 mt-0 rounded-pill p-2'}>Редагувати</button>
                                </div>)
                            }
                        </div>

                        {/*<JsonEditor*/}
                        {/*    data={ data.data }*/}
                        {/*    rootName={data.label}*/}
                        {/*    theme={'monoLight'}*/}
                        {/*    onUpdate={ ({newData} ) => {*/}
                        {/*        // Do something with the new data, e.g. update jsonData*/}
                        {/*        console.log(newData)*/}
                        {/*    }}*/}
                        {/*    // setData={ setJsonData } // optional*/}
                        {/*    // { ...otherProps }*/}
                        {/*/>*/}
                    </div>

                </div>

            </div>
            <br/>
            <small className="text-secondary d-flex p-2">
                Код Текста: <a href="tg://ukrparty2" className={'nav-link m-2 mt-0 mb-0 text-info'}>                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"                         className="bi bi-telegram" viewBox="0 0 16 16">                        <path                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>                    </svg> Telegram</a>
            </small>
            <input type="text"
                   className={'form-control rounded-pill p-3'}
                   value={"Мерщій пишіть нам в  <a href=\"tg://ukrparty2\" class='nav-link text-info'>                    <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" fill=\"currentColor\"                         className=\"bi bi-telegram\" viewBox=\"0 0 16 16\">                        <path                            d=\"M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09\"/>                    </svg> Telegram</a>"}/>
            {/*<div className="fixed-bottom1 text-center col-md-12 col-12 m-auto">*/}
            {/*    <button type={'submit'} onClick={() => {}} className={'btn btn-dark rounded-pill p-3 col-md-12 col-12'}>Зберегти</button>*/}
            {/*</div>*/}

        </div>


        }

    </div>);
}

export default EditDataDetailPage;
