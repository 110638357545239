
import React from 'react';

function CardComponents(props) {

    const item = props.item;

    return (<div key={props.key} id={item.id} className={'card-party pb-3'}>
        <div className="text-start border p-md-5 p-3 shadow-sm rounded">
            <h3 className={'display-3'}>{item.title}</h3>
            <div className="lead mt-5">
                {
                    item.description.split('\n').map(paragraph => <p>{paragraph}</p>)
                }
            </div>
            <div className="connect-telegram mt-5 text-md-start text-center">
                {/*<a href="/party" target={'_blank'} className={'btn p-5'}>Детальніше</a>*/}
                <a href="https://t.me/ukrparty2"  target={'_blank'} className={'p-3 rounded-pill col-md-4 col-12 shadow-sm text-white btn btn-success rounded-0 p-3'}>Купити партію</a>
                <a href="mailto:sale@2025ukr.party" target={'_blank'} className={'btn p-5'}>sale@2025ukr.party</a>
            </div>
        </div>
    </div>);
}

export default CardComponents;
