
import React, {useEffect, useState} from 'react';
import LocalJsonData from "../../../data";

function EditDataPage(props) {

    const _cards = LocalJsonData('');

    const [isAuth, setIsAuth] = useState(window.localStorage.getItem('is_auth'))
    const [codeAuth, setCodeAuth] = useState(window.localStorage.getItem('auth_code'))

    const handleExit = (e) => {
        e.preventDefault();
        window.localStorage.clear();
        window.location.href = '/edit-data/auth'
    }

    if(!isAuth){
        window.location.href = '/edit-data/no-auth'
    }

    return (<div className={'container col-md-6 col-12 m-auto min-vh-100 p-md-5 p-0'}>

        <h1 className={'text-secondary opacity-25'}>
            Редагування даних
        </h1>

        <div className="d-flex flex-wrap mt-5 mb-5">

                {
                    _cards.map((card, i) => <div key={i} className={'p-2 col-md-6 col-12'}>
                        <div className={'border rounded-5 p-5 shadow-sm '}>
                            <h3>
                                <b>
                                    {card.title}
                                </b>
                            </h3>
                            <p className={'text-secondary'}>
                                {card.description}
                            </p>
                            <br/>
                            <a href={card.route} className={'btn btn-dark rounded-pill p-3'}>Редагувати</a>
                        </div>
                    </div>)
                }

        </div>

        <div className="exit-btn text-center mt-3 d-flex flex-column">
            <small className="text-secondary">
                Код авторизації: <b>{codeAuth}</b>
            </small>
            <button type={"submit"} className={'btn text-danger'} onClick={handleExit}>
                Вихід
            </button>
        </div>

    </div>);
}

export default EditDataPage;
