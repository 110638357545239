import React from "react";

import partysData from './party';
import textsData from './text';

export default function LocalJsonData(file){
    const data = {
        texts: {
            id: "texts",
            // data: textsData,
            title: "Заголовкі",
            label: "Текст на головній сторінці",
            description: "Текст на головній сторінці сайту",
            route: "/edit-data/f/texts"
        },
        partys: {
            id: "partys",
            // data: partysData,
            title: "Партії",
            label: "Зміст партій",
            description: "Такст карточок про партії",
            route: "/edit-data/f/partys"
        }
    };

    const cards = [
        {
            title: "Заголовкі",
            description: "Текст на головній сторінці сайту",
            route: "/edit-data/f/texts"
        }
        // ,
        // {
        //     title: "Партії",
        //     description: "Такст карточок про партії",
        //     route: "/edit-data/f/partys"
        // }
    ];

    return file !== '' ? data[file] : cards;
}
