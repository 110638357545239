
import './App.css';
import {Routes, Route, Link} from "react-router-dom";
import {MotionAnimate} from "react-motion-animate";
import PageNotFound from "./pages/404";
import LayoutsComponents from "./components/layouts";
import DetailPage from "./pages/detail";
import MainPage from "./pages/main";
import InfoPage from "./pages/info";
import SiteMapPage from "./pages/map";
import AuthEditDataPage from "./services/edit_data/pages/auth";
import EditDataPage from "./services/edit_data/pages/data";
import EditDataDetailPage from "./services/edit_data/pages/edit";

function App() {
  return (
    <>
        <LayoutsComponents>
          <Routes>

            <Route index exact path={'/'} element={<MainPage />}/>

            {/*<Route path="party" element={ <DetailPage />}/>*/}

            <Route path="edit-data" element={ <EditDataPage />}/>
            <Route path="edit-data/f/:json" element={ <EditDataDetailPage />}/>
            <Route path="edit-data/auth" element={ <AuthEditDataPage />}/>

            {/*<Route path="information" element={ <InfoPage />}/>*/}
            {/*<Route path="site-map" element={ <SiteMapPage />}/>*/}

            <Route path="*" element={ <PageNotFound />}/>

          </Routes>
        </LayoutsComponents>
    </>
  );
}

export default App;
