
import React, {useState} from 'react';
import authCodes from '../auth_codes/list';

function AuthEditDataPage(props) {

    const [code, setCode] = useState();
    const [error, setError] = useState();

    const handleChangeCode = (e) => {

        setCode(e.target.value)

        if(code !== ''){
            setError('')
        }
    }

     const handleAuth = (e) =>{

        e.preventDefault()

         if(['', null, undefined].includes(code)){
             setError('Введіть код!')
         }else{

             const codes = authCodes.map((code) => code.code);

               if(codes.includes(code)){
                   window.localStorage.setItem('auth_code', code)
                   window.localStorage.setItem('is_auth', 'true')
                   window.location.href = '/edit-data'
               }else{
                   setError('Код не правильний!')
               }
         }

     }

    return (<div className={'container col-md-5 col-12 m-auto min-vh-100 p-md-5 p-0'}>
        <h1 className={'text-secondary opacity-25'}>
            Авторизація
        </h1>

        <div className="border rounded-5 shadow-sm p-5 mt-5">

            <div className={'lead text-secondary text-start col-md-6 col-12 m-auto'}>

                <h3>
                    <b>
                        Код авторизації
                    </b>
                </h3>
                {error && <p className={'text-danger lead p-1'}>{error}</p>}
                <div className="group-form d-flex">
                    <input type="password" value={code} onChange={handleChangeCode} className={'form-control rounded-pill m-2 p-2'} placeholder={'Код'}/>
                    <button type={'submit'} onClick={handleAuth} className={'btn btn-dark rounded-pill m-2'}>
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"/>
                        </svg>
                    </button>
                </div>


            </div>

        </div>


    </div>);
}

export default AuthEditDataPage;
